<template>
  <app-data-table
    :headers="filterItemsWithFulfilledCondition(tableHeaders)"
    :items="formattedTableData"
    :loading="loadingList"
    :total-data-length="count"
  >
    <template #title>
      <div class="table-title__content">
        <span class="text-h5">{{ $tc('users.counted', count) }}</span>
      </div>
    </template>

    <template #item="{ item }">
      <tr>
        <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
          <app-status-chip :value="item.status" />
        </app-table-data>
        <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
          <div class="semi-bold">{{ item.creditorName }}</div>
        </app-table-data>
        <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
          {{ item.userName }}
        </app-table-data>
        <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
          {{ item.sapNumber || '-' }}
          <div class="subtitle-1 grey--text">
            {{ item.isSentToSap ? $t('users.sent') : $t('users.notSent') }}
          </div>
        </app-table-data>
        <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
          {{ item.email }}
        </app-table-data>
        <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
          {{ item.city }}
        </app-table-data>
        <app-table-data :sorted="isSorted(6)" :header="tableHeaders[6]">
          {{ $t(`users.roles.${item.roles[0]}`) }}
        </app-table-data>
        <app-table-data v-if="!isProductAdmin" :sorted="isSorted(7)" :header="tableHeaders[7]">
          {{ item.productTrans || '-' }}
        </app-table-data>
        <td class="text-center">
          <template v-if="isForeignUser(item.product)">
            <app-icon-btn v-if="!isLocal" :href="urlFromProductType(item.product)" target="_blank">
              <app-tooltip :text="$t('users.switchPortal', { productName: item.productTrans })">
                <v-icon>mdi-open-in-new</v-icon>
              </app-tooltip>
            </app-icon-btn>
            <app-icon-btn v-else @click="changeLocalProduct(item.product)">
              <app-tooltip :text="$t('users.switchPortal', { productName: item.productTrans })">
                <v-icon>mdi-open-in-new</v-icon>
              </app-tooltip>
            </app-icon-btn>
          </template>

          <app-btn
            v-else
            min-height="unset"
            class="py-1 px-2"
            outlined
            @click="replaceToken(item.id)"
          >
            {{ $t('select') }}
          </app-btn>
        </td>
      </tr>
    </template>

    <template #no-data>
      <p>{{ $t('users.noData') }}</p>
    </template>
  </app-data-table>
</template>

<script>
import ProductType from '@/statics/productType';
import TableMixin from '@/mixins/TableMixin';
import formatProduct from '@/helper/filter/formatProduct';
import {
  ADMIN_REFRESH_TOKEN_KEY,
  ADMIN_TOKEN_KEY,
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY
} from '@/plugins/vue-auth';
import { LOCAL_PRODUCT_STORAGE } from '@/statics/storageKeys';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions, mapState } from 'vuex';
import { resolveFromURL } from '@/helper/productResolver';

export default {
  name: 'UsersTable',

  mixins: [TableMixin],

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      user: this.$auth.token(),
      refreshToken: this.$auth.token(REFRESH_TOKEN_KEY)
    };
  },

  computed: {
    ...mapState(NAMESPACE, ['users', 'loadingList']),
    formattedTableData() {
      return JSON.parse(JSON.stringify(this.tableData)).map((entry) => ({
        ...entry,
        productTrans: formatProduct(entry.product)
      }));
    },
    tableHeaders() {
      return [
        { text: this.$t('status'), value: 'status', sortable: false },
        { text: this.$t('creditors.creditorName'), value: 'name', alignLeft: true },
        { text: this.$t('users.userName'), value: 'username' },
        { text: this.$t('sapNumber'), subText: this.$t('users.sentToSap'), value: 'sapNumber' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('city'), value: 'city' },
        { text: this.$t('users.role'), value: 'roles', sortable: false },
        { text: this.$t('product'), condition: !this.isProductAdmin, sortable: false },
        { text: this.$t('users.actions'), value: null, sortable: false }
      ];
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchUserToken']),

    isForeignUser(product) {
      return product !== resolveFromURL();
    },

    urlFromProductType(productType) {
      if (productType === ProductType.DV_FACTORING) {
        return this.buildUrl(process.env.VUE_APP_FACTORING_HOSTNAME);
      }

      if (productType === ProductType.DV_BILLING) {
        return this.buildUrl(process.env.VUE_APP_BILLING_HOSTNAME);
      }

      if (productType === ProductType.FW_FACTORING) {
        return this.buildUrl(process.env.VUE_APP_TEAMFACTOR_HOSTNAME);
      }

      if (productType === ProductType.DAB_BILLING) {
        return this.buildUrl(process.env.VUE_APP_DAB_HOSTNAME);
      }

      if (productType === ProductType.DAA_BILLING) {
        return this.buildUrl(process.env.VUE_APP_DAA_HOSTNAME);
      }

      if (productType === ProductType.FAS_BILLING) {
        return this.buildUrl(process.env.VUE_APP_FAS_HOSTNAME);
      }

      if (productType === ProductType.VHV_FACTORING) {
        return this.buildUrl(process.env.VUE_APP_VHV_HOSTNAME);
      }

      if (productType === ProductType.TPF_FACTORING) {
        return this.buildUrl(process.env.VUE_APP_TPF_HOSTNAME);
      }

      return undefined;
    },

    buildUrl(hostname) {
      let url = `${window.location.protocol}//${hostname}`;

      if (window.location.port) {
        url += `:${window.location.port}`;
      }

      return url;
    },

    async replaceToken(userId) {
      this.setLoadingCurrent();
      const { content, error } = await this.fetchUserToken(userId);

      if (error) return;

      this.$auth.token(ADMIN_TOKEN_KEY, this.user);
      this.$auth.token(ADMIN_REFRESH_TOKEN_KEY, this.refreshToken);

      await this.$router.replace(this.routeName.DASHBOARD);

      this.$auth.fetch({}).then(() => {
        this.$auth.token(AUTH_TOKEN_KEY, content.token);
        this.$auth.token(REFRESH_TOKEN_KEY, content.refresh_token);
        this.setCurrent(userId);
        this.resetLoadingCurrent();
      });
    },

    changeLocalProduct(productType) {
      localStorage.setItem(LOCAL_PRODUCT_STORAGE, productType);
      window.location.reload();
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table.app-data-table tr td {
  vertical-align: middle;
}
</style>
