<template>
  <app-select
    filter
    :items="states"
    :label="$t('status')"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import formatUserStatus from '@/helper/filter/formatUserStatus';
import { USER_STATUS_GROUPS } from '@/modules/Admin/statics/userStatus';

export default {
  name: 'UserStatusSelect',

  computed: {
    states() {
      return Object.keys(USER_STATUS_GROUPS).map((status) => ({
        label: formatUserStatus(status),
        value: status
      }));
    }
  }
};
</script>
