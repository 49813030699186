<template>
  <app-select
    filter
    :items="states"
    :label="$t('users.role')"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { COMPANY_USER_ROLES, UserManagementRole } from '@/statics/role';

export default {
  name: 'RoleSelect',

  computed: {
    states() {
      return [UserManagementRole.CREDITOR_USER, ...COMPANY_USER_ROLES].map((option) => ({
        label: this.$t(`users.roles.${option}`),
        value: option
      }));
    }
  }
};
</script>
