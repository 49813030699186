<template>
  <v-container fluid pa-0>
    <users-filter />
    <users-table :table-data="users" :count="usersCount" />
  </v-container>
</template>

<script>
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import UsersFilter from '@/modules/Admin/components/Users/UsersFilter';
import UsersTable from '@/modules/Admin/components/Users/UsersTable';
import { NAMESPACE } from '@/modules/Admin/store';
import { USER_STATUS_GROUPS } from '@/modules/Admin/statics/userStatus';
import { mapActions, mapState } from 'vuex';
import { QueryParam } from '@/statics/queryParam';
import { UserManagementRole } from '@/statics/role';

export default {
  name: 'Users',

  mixins: [CreditorListMixin, ListFilterMixin],

  components: {
    UsersTable,
    UsersFilter
  },

  data: () => ({
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'city',
      'creditorName',
      'email',
      'officialVoucherNumber',
      'product',
      'role',
      'sapNumber',
      'status',
      'userName'
    ],
    defaultSorting: {
      sortBy: ['sapNumber'],
      sortDesc: [false]
    }
  }),

  computed: {
    ...mapState(NAMESPACE, ['users', 'usersCount']),
    defaultFilter() {
      return {
        product: [this.productType],
        role: [UserManagementRole.CREDITOR_USER]
      };
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchUsers']),

    loadUsers(query = this.defaultQuery) {
      this.fetchUsers({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });
    },

    buildFilters() {
      let filters = {};

      if (this.statusSelection.length > 0) {
        filters.status = this.statusSelection.flatMap((state) => USER_STATUS_GROUPS[state] ?? []);
      }

      if (this.creditorNameSelection.length > 0) {
        filters.name = this.creditorNameSelection;
      }

      if (this.userNameSelection.length > 0) {
        filters.username = this.userNameSelection;
      }

      if (this.sapNumberSelection.length > 0) {
        filters.sapNumber = this.sapNumberSelection;
      }

      if (this.citySelection.length > 0) {
        filters.city = this.citySelection;
      }

      if (this.roleSelection.length > 0) {
        filters.role = this.roleSelection;
      }

      if (this.productSelection.length > 0) {
        filters.product = this.productSelection;
      }

      if (this.officialVoucherNumberSelection.length > 0) {
        filters.voucher = this.officialVoucherNumberSelection;
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    }
  },

  watch: {
    '$route.query': {
      deep: true,
      handler(query) {
        this.updateTableQueries(query, this.queryValues, this.loadUsers);
      }
    }
  },

  created() {
    this.loadUsers();
  }
};
</script>
